<template>
    <div id="termekek" class="products pb-5 pt-2 position-relative">
        <div class="container-xl">                    
            <div class="row pb-5 mb-5">                
                <div class="col-md-12 d-flex flex-column text-center pt-md-5">
                    <h2 class="font-header text-black fw-bold text-center my-7">
                        Részt vevő termékek
                    </h2>
                </div>
                <div class="col-12 col-lg-10 offset-lg-1">
                    <VueSlickCarousel v-bind="settings">
                        <div class="bear-top"><img src="imgs/products/product-1_new.png" class="img-fluid" alt="pom-bar"/></div>
                        <div class="bear-left"><img src="imgs/products/product-2_new.png" class="img-fluid" alt="pom-bar"/></div>
                        <div class="bear"><img src="imgs/products/product-3_new.png" class="img-fluid" alt="pom-bar"/></div>
                        <div class="bear-top bear-left"><img src="imgs/products/product-4_new.png" class="img-fluid" alt="pom-bar"/></div>
                        <div class="bear-right"><img src="imgs/products/product-5_new.png" class="img-fluid" alt="pom-bar"/></div>
                        <div class="bear-top"><img src="imgs/products/product-6_new.png" class="img-fluid" alt="pom-bar"/></div>
                        <div class="bear-left"><img src="imgs/products/product-7_new.png" class="img-fluid" alt="pom-bar"/></div>
                        <div class="bear"><img src="imgs/products/product-8_new.png" class="img-fluid" alt="pom-bar"/></div>
                        <div class="bear-top bear-left"><img src="imgs/products/product-9_new.png" class="img-fluid" alt="pom-bar"/></div>
                        <div class="bear-right"><img src="imgs/products/product-10_new.png" class="img-fluid" alt="pom-bar"/></div>                        
                    </VueSlickCarousel>
                </div>
            </div>         
        </div>
    </div>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'

export default {
    components: { VueSlickCarousel },
    data() {
      return {
        settings: {
            arrows: true,
            dots: true,
            slidesToShow: 5,
            slidesToScroll: 5,
            initialSlide: 0,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
        },
      }
    },
    methods: {   
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekban_reszt_vevo_termekek',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>