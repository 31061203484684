<template>
    <div>
        <div id="jatekszabaly" class="rules position-relative block--big">
            <div class="container-xl">

                <div class="row px-2">
                    <h2 class="text-white text-center mb-5 pb-5">
                        A játék lépései
                    </h2>
                     <img src="@/assets/imgs/pombar.svg" alt="Pombar" class="position-absolute d-none d-lg-block" style="width:200px;"/>
                     <img src="@/assets/imgs/pombar.svg" alt="Pombar" class="d-block d-md-none mx-auto mb-2" style="width:200px;"/>
                    <div class="col-12 col-lg-4 rules-container text-center">
                        <img src="@/assets/imgs/vasaroljon.png" alt="Pombar" class="img-fluid" />
                        <h3 class="text-secondary">Vásároljon!</h3>
                        <p class="text-white" style="font-size:15px;">Vásároljon <b>legalább 1 db</b> a promócióban részt vevő <b>POM-BÄR terméket 2024. július 01. és szeptember 30.</b> között. A <b>vásárlást igazoló eredeti bizonylat</b> sértetlen, ép állapotban történő megőrzése <b>2024. október 31-ig</b> szükséges.</p>
                    </div>   
                    <div class="col-12 col-lg-4 rules-container text-center">
                        <img src="@/assets/imgs/regisztraljon.png" alt="Pombar" class="img-fluid" />
                        <h3 class="text-secondary">Regisztráljon!</h3>
                        <p class="text-white" style="font-size:15px;">Regisztráljon, majd töltse fel a vásárlást igazoló bizonylat adatait és játsszon a nyereményekért <b>2024. augusztus 01. és szeptember 30.</b> között!</p>
                    </div>  
                    <div class="col-12 col-lg-4 rules-container text-center">
                        <img src="@/assets/imgs/nyerjen.png" alt="Pombar" class="img-fluid" />
                        <h3 class="text-secondary">Nyerjen!</h3>
                        <p class="text-white" style="font-size:15px;">Nyerjen napi és heti ajándékot vagy extra nyereményt is, ha Tesco vagy Spar üzletben vásárolt!</p>
                        <div class="collapse" id="collapseExample">
                            <div class="bg-primary text-white text-start" style="font-size:15px;">
                                <ul><li class="pb-2">Napi nyeremények: <b>naponta 30 db plüss POM-BÄR Maci és naponta 5 db POM-BÄR piknik szett</b></li>
                                <li class="pb-2">Heti nyeremény: hetente <b>1 db 200.000 Ft értékű Hunguest Hotels Ajándékkártya</b>, melyet akár szuper élményfürdős családi wellnessre is beválthat!</li>
                                <li class="pb-2">Amennyiben Tesco üzletben vásárolt 2024. augusztus 15-28. között, indulhat a <b>választható fődíjért is, mely vagy MacBook Air 13” M3 vagy Játékkonzol + 1 db hozzávaló játék + VR szemüveg vagy 500.000 Ft értékkel feltöltött bankkártya</b></li>
                                <li class="pb-2">Ha Spar üzletben vásárolt 2024. augusztus 15-28. között, úgy indulhat az <b>iPhone 15 Pro telefonért</b> is!</li></ul>
                            </div>
                        </div>
                        <a class="btn btn-secondary" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" @click="active = !active">
                            <span v-html="active ? 'Kevesebb':'Több'"></span>&nbsp;mutatása
                        </a>
                    </div>  
                    
                </div>
            </div>        
        </div>
        <div id="nyeremenyek" class="winnings position-relative block--big">
            <div class="container-xl">

                <div class="row px-2">
                    <h2 class="text-black text-center">
                        Nyeremények
                    </h2>
                    <div class="col-12 mt-5 pt-5 d-flex flex-column justify-content-center align-items-center">
                        <img src="@/assets/imgs/pombar.svg" alt="Pombar" class="img-fluid d-block d-lg-none" />
                        <img src="@/assets/imgs/winnings-all.png" alt="Pombar" class="img-fluid d-none d-lg-block" />
                        <img src="@/assets/imgs/winnings-all-mobile.png" alt="Pombar" class="img-fluid d-block d-lg-none" />
                        <p class="text-black"><i>A képek illusztrációk.</i></p>
                    </div>   
                    
                </div>
            </div>        
        </div>
        <div class="winnings position-relative block--big" v-if="$store.state.extra_status">
            <div class="container-xl">

                <div class="row px-2">
                    <h2 class="text-black text-center">
                        Spar extra nyeremény
                    </h2>
                    <h3 class="text-black text-center fw-bold">Extra nyeremény kizárólag a SPAR-csoport üzleteiben:</h3>
                    <div class="col-12 mt-5 pt-5 d-flex flex-column justify-content-center align-items-center">
                        <img src="@/assets/imgs/spar-extra.png" alt="Pombar" class="img-fluid mb-5" style="max-width:500px;width:100%;"/>
                        <p class="text-black mt-5 pt-5"><i>A képek illusztrációk.</i></p>
                    </div>  
                </div>
            </div>        
        </div>
        <div class="winnings position-relative block--big" v-if="$store.state.extra_status">
            <div class="container-xl">

                <div class="row px-2">
                    <h2 class="text-black text-center">
                        Tesco extra nyeremények
                    </h2>
                    <h3 class="text-black text-center fw-bold">Választható extra nyeremény kizárólag a TESCO-ban</h3>
                    <div class="col-12 mt-5 pt-5 d-flex flex-column justify-content-center align-items-center">
                        <!-- <img src="@/assets/imgs/pombar.svg" alt="Pombar" class="img-fluid d-block d-lg-none mb-5" /> -->
                        <img src="@/assets/imgs/tesco-winnings.png" alt="Pombar" class="img-fluid d-none d-lg-block" />
                        <img src="@/assets/imgs/tesco-winnings-mobile.png" alt="Pombar" class="img-fluid d-block d-lg-none" />
                        <p class="text-black mt-5 pt-5"><i>A képek illusztrációk.</i></p>
                    </div>   
                    
                </div>
            </div>        
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            active: false
        }
    },
    methods:{
        setGtm(btnName, eventName){
            this.GTtrackGA4({
                'event' : eventName,
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'button': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
            })
        }
    }
}
</script>