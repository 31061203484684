<template>
    <div id="nyertesek" class="winners block--big bg-primary">
             <img src="@/assets/imgs/winnings-icon.svg" class="img-fluid mx-auto d-block">
            <h2 class="text-center mt-0 text-white mb-5">
                Nyertesek
            </h2>
            <div class="container-xl">
                <div class="row">                
                    <div class="col-12 col-xl-8 offset-xl-2 mb-0 mb-md-5 text-center order-1">
                        <p class="m-0 winners__text text-secondary fw-bold">                        
                            <b>Kedves Játékosunk!</b><br><br>

                            A napi nyeremények nyertesei az adott napot követő első munkanapon (pénteki/szombati/vasárnapi nyeremények esetében a következő hétfőn, ünnepnap esetében a következő munkanapon), a heti nyeremények nyertesei hétfőnként, vagy hétfői ünnepnap esetén a hét első munkanapján (2024. augusztus 5., 12., 21., 26., szeptember 02., 09., 16., 23., és az utolsó Játékhét tekintetében október 01.) kerülnek kiértesítésre a Lebonyolító által küldött e-mail üzenetben.<br><br>A Tesco és Spar extra nyeremények sorsolására 2024. szeptember 3-án 15:00 órakor, közjegyző jelenlétében, gépi sorsolással, nyilvánosan kerül sor.
                             
                        </p>
                    </div>                                                
                </div>
            </div>
            <!-- NYertesek -->
            <div class="container-xl block--big py-5 mt-5">
                <div class="row">     
                    <div class="col-12">
                        <!-- TAB -->
                        <div class="winners-button-select mb-5" >
                            <button 
                                class="btn text-white fw-700 px-3" 
                                :class="winnerState === 'napi' ? 'active text-secondary':''" 
                                v-on:click="setWinnerState('napi')"
                            >
                                Napi <span class="d-none d-md-block ms-1"> nyertesek</span>
                            </button>
                            <button 
                                class="btn text-white fw-700 px-3" 
                                :class="winnerState === 'heti' ? 'active text-secondary':''"  
                                v-on:click="setWinnerState('heti')"
                            >
                                Heti <span class="d-none d-md-block ms-1"> nyertesek</span>
                            </button> 
                         <button 
                                class="btn fw-700 text-white px-3"
                                :class="winnerState === 'spar' ? 'active text-secondary':''" 
                                v-on:click="setWinnerState('spar')"
                            >
                                Spar extra <span class="d-none d-md-block ms-1"> nyertes</span>
                            </button>
                            <button 
                                class="btn fw-700 text-white px-3"
                                :class="winnerState === 'tesco' ? 'active text-secondary':''" 
                                v-on:click="setWinnerState('tesco')"
                            >
                                Tesco extra <span class="d-none d-md-block ms-1"> nyertes</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 text-center py-3"  v-if="winnerState === 'napi'">
                        <h3 class="text-white fw-bold">A nyertesek megtekintéséhez <br>kattintson az adott napra!</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-4 text-lg-end text-center mb-3" v-if="winnerState === 'napi'">
                        <Datepicker :inline="true" :disabled-date="disabledDates" v-model="dateValue" value-type="format" format="YYYY-MM-DD"></Datepicker>
                    </div>
                    <div class="col-12" :class="winnerState !== 'napi'? '':'col-lg-8'">
                        <!-- TABLE -->
                        <div class="winners__border mb-5">
                            <div class="winners__table-wrap winners__table-wrap--heading">                        
                                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  >
                                    <thead>
                                        <tr class="winners-top font-primary" style="border-bottom: 1px solid #e45463;">
                                            <th class="w-33 text-secondary">Nyertes neve</th>
                                            <th class="w-33 text-secondary">Város</th>       
                                            <th class="w-33 text-secondary">Nyeremény</th>                      
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="winners__table-wrap winners__table-wrap--bg text-dark">                        
                                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  :class="[`winner-table_${winnerState}`]" >
                                    <tbody v-if="filteredWinners && filteredWinners.length">                          
                                        <tr class="winners-list fs-14 text-white" v-for="(winner, winnerindex) in filteredWinners" :key="winnerindex">
                                            <td class="winners-id fw-700 text-white w-33 p-3">
                                                <div>{{ winner.name }}</div>                                     
                                            </td>                          
                                            <td class="winners-product text-white text-right text-md-left w-33 p-3">
                                                <span>{{winner.field_1}}</span>
                                            </td>
                                            <td class="winners-product text-white text-right text-md-left w-33 p-3">
                                                <span v-if="winner.type == 'napi_pluss_maci'">1 db Pom-Bär Maci</span>
                                                <span v-else-if="winner.type == 'napi_picnic_kosar'">1 db Pom-Bär piknik szett</span>
                                                <span v-else-if="winner.type == 'heti'">1 db 200.000 Ft értékű Hunguest Hotels Ajándékkártya</span>
                                                <span v-if="winner.type == 'extra_spar'">1 db iPhone 15 Pro</span>
                                                <span v-if="winner.type == 'extra_tesco'"><span v-if="winner.field_2">{{winner.field_2}}</span></span>
                                            </td>
                                        </tr>                            
                                    </tbody>
                                    <tbody v-else>
                                        <!-- v-if="loginShow" -->
                                        <tr>
                                            <td v-if="winnerState == 'extra_tesco' || winnerState == 'extra_spar'" class="no-winner text-white text-center py-3" colspan="2">
                                                Hamarosan kiderül, ki lett a nyertes
                                            </td>
                                            <td v-else class="no-winner text-white text-center py-3" colspan="2">
                                                Hamarosan kiderül, kik lettek a nyertesek
                                            </td>                                
                                        </tr>
                                        <!--<tr v-else>
                                            <td class="no-winner text-center py-3" colspan="2">
                                                A kisorsolt nyertesek részére a nyeremények átadása megtörtént!
                                            </td>
                                        </tr>-->
                                    </tbody>
                                </table>                        
                            </div>

                            
                        </div>

                        <div class="d-flex justify-content-center py-4" v-if="needMoreBtn">
                                <button target="_blank" type="button" class="btn btn-primary" v-on:click="moreWinners(true)">Mutasd a további nyerteseket</button>
                        </div>
                    </div>           
                    
                </div> 
            </div>
        
    </div>
</template>


<script>
import Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    components: {
        Datepicker
    },
    data () {
        return {
            winnersAll:[],
            winnerState:'napi',
            needMoreBtn: false,
            needMoreWinners: false,
            dateValue: "",
            refreshKey: 0
        }
    },
    computed:{
        filteredWinners() {
            let result = []
            let _this = this
            this.refreshKey;

            if(this.winnersAll ?? []){                
                
                //result = this.winnersAll.filter(w => w.type === this.winnerState)

                _this.winnersAll.forEach(w => {
                    if (_this.winnerState === 'napi'){
                        if (w.type == 'napi_pluss_maci' || w.type == 'napi_picnic_kosar'){
                            if(_this.dateValue === w.winning_time){
                                result.push(w)
                            }
                        }
                    } else if (_this.winnerState == 'heti'){
                        if (w.type == 'heti'){                            
                            result.push(w)
                        }
                    }else if (_this.winnerState == 'tesco'){
                        if (w.type == 'extra_tesco'){                            
                            result.push(w)
                        }
                    }else if (_this.winnerState == 'spar'){
                        if (w.type == 'extra_spar'){                            
                            result.push(w)
                        }
                    }

                })

                if(result.length > 10 && !this.needMoreWinners){
                    this.checkBtn(true);
                    return result.slice(0,10);
                }else{
                    this.checkBtn(false);
                    return result;
                }
            }
            this.checkBtn(false);
            return result;
        }
    },
    watch:{      
        dateValue(){
            this.refreshKey++;
            console.log(this.dateValue);

        }
    },
    methods: {
        disabledDates: function (date) {
            return date < new Date(2024, 7, 1) || date > new Date(2024, 8, 30);
        },
        setDailyWinners(date){  
            console.log(date);

        },
        setWinnerState(newState) {
            this.moreWinners(false); 
            this.winnerState = newState;      
        },
        checkBtn(newState) {
            this.needMoreBtn = newState;
        },
        moreWinners(newState){
            this.needMoreWinners = newState;
        },
      /*  selectWinners(){
            let _this = this
            this.wins.forEach( item => {                
                let winTypeWinners = _this.winnersAll.filter( element => element.type == item.type )
                //console.log('winTypeWinners',winTypeWinners)
                if (winTypeWinners)
                    item.winners = winTypeWinners
            })
        }*/
    },
    created(){

        this.dateValue = new Date();

        this.get('info/winners',{})
        .then((resp) => {
          this.winnersAll = resp.data.winners
         // this.winnerState = "napi";
          //this.selectWinners()
        }).catch((err) => {
            console.log(err)
        })

      //  this.setWinnerState('napi')
    }
    
}
</script>