<template>
    <div id="regisztracio" class="register block--big">
        <div class="container-xl pt-md-5">                        
            <div class="row" v-if="$store.state.promoStatus == 'live' ">                
                <!-- ITT KEZDŐDIK A FORM -->
                <ValidationObserver 
                    class="col-12 col-xl-10 offset-xl-1"
                    ref="observerReg" 
                    v-slot="{ handleSubmit }"
                    tag="div"
                >
                    <div class="row">                
                        <div class="col-12 col-xl-8 offset-xl-2 mb-0 mb-md-2">
                            <h2 class="font-header text-black text-center mb-6 mb-sm-4 mt-0 fw-800">Regisztráció</h2>
                            <p class="text-black fw-500 text-center mb-7 pb-xl-6">Személyes adatait úgy adja meg, hogy azokat személyi igazolványával és lakcímkártyájával tudja igazolni, amennyiben nyerteseink között tudhatjuk majd. Az adatok megadása, így a játékban való részvétel önkéntes.</p>
                        </div>
                    </div>    
                    <form style="box-shadow: 0px 4px 70px 0px rgba(21, 71, 52, 0.40);border-radius: 30px;"
                        @submit.prevent="handleSubmit(onSubmit)">     
                        <div class="form-inner-container form__inner" style="border-radius: 30px 30px 0 0;">           
                            <div class="row ">
                                <!-- VEZETÉKNÉV -->
                                <!-- todo: regex:^[a-zA-Z\s]*$ -->
                                <div class="col-12 col-md-6">
                                    <CustomInput
                                        label="Vezetéknév"
                                        name="last_name"
                                        placeholder="pl.: Molnár"
                                        tooltip="A játékos személyi igazolványába jegyzett vezetéknév."
                                        v-model="form.last_name"
                                        rules="required|min:2|customRegex"
                                    />  
                                </div>
                                
                                <!-- KERESZTNÉV -->
                                <div class="col-12 col-md-6">
                                    <CustomInput
                                        label="Keresztnév"
                                        name="first_name"
                                        placeholder="pl.: Kata"
                                        tooltip="A játékos személyi igazolványába jegyzett keresztnév."
                                        v-model="form.first_name"
                                        rules="required|min:2|customRegex"
                                    />
                                </div>
                                
                                <!-- EMAIL -->
                                <div class="col-12 col-md-6">
                                    <CustomInput
                                        label="E-mail cím"
                                        name="email"
                                        placeholder="pl.: molnarkata@gmail.com"
                                        tooltip="A játékos e-mail címe."
                                        v-model="form.email"
                                        rules="required|email"                                    
                                    />
                                </div>
                                <!-- EMAIL MÉGEGYSZER -->
                                <div class="col-12 col-md-6">
                                    <CustomInput
                                        label="E-mail cím még egyszer"
                                        name="emailConf"
                                        placeholder="pl.: molnarkata@gmail.com"
                                        tooltip="A játékos e-mail címe újra."
                                        v-model="form.emailConf"
                                        rules="required|email|confirmed:email"
                                    />
                                </div>

                                <!-- JELSZÓ -->
                                <div class="col-12 col-md-6">
                                    <CustomInput
                                        label="Jelszó"
                                        type="password"
                                        name="password"
                                        placeholder="Minimum 6 karakter"
                                        tooltip="Legalább 6 karakternek kell lennie és speciális karaktereket nem tartalmazhat."
                                        v-model="form.password"
                                        rules="required|min:6|alpha_num"
                                    />
                                </div>

                                <!-- JELSZÓ MEGERŐSÍTÉS -->
                                <div class="col-12 col-md-6">
                                    <CustomInput
                                        label="Jelszó még egyszer"
                                        type="password"
                                        name="passconf"
                                        placeholder="Minimum 6 karakter"
                                        tooltip="Jelszó még egyszer."
                                        v-model="form.passconf"
                                        rules="required|confirmed:password"
                                    />
                                </div>

                                <!-- TELEFONSZÁM -->
                                <div class="col-12 col-md-6 phone-wrap">
                                    <div class="form-label">Telefonszám<span class="label-red"> *</span></div>
                                    <div class="d-flex w-100 align-items-start position-relative">
                                        <span class="me-2 d-block phone-prefix">+36</span>
                                        <CustomInput
                                            class="w-100"
                                            label=""
                                            name="phone"
                                            tooltip="Egy telefonszám, amelyen a játékos elérhető."
                                            v-model="phoneShort"                                        
                                            @input="formatPhoneNumber($event)"                                        
                                            rules="required|customPhone"
                                        />
                                    </div>
                                </div>
                                <div class="mt-3 mb-md-4 text-sm-center d-flex">                                
                                        <span class="text-red fw-bold">*</span>                                    
                                        <p class="m-0 info-text text-black">A csillaggal jelölt mezők kitöltése szükséges.</p>
                                </div>

                                <!-- KEDVENC ÁRUHÁZ -->
                            <!--<div class="col-12 col-md-6">
                                    <div class="form-label">Kedvenc magyarországi Auchan áruház</div>
                                    <DropDown
                                        label=""
                                        name="favourite_shop"
                                        placeholder="Válassz egyet!"
                                        :options="shops"
                                        @selectedItem="form.favourite_shop = $event"
                                    />
                                </div>-->

                            <!--<div class="col-12">
                                    <div class="form-label">Melyik a kedvenc Hungaria pezsgőd?</div>
                                    <DropDown
                                        label=""
                                        name="favourite_shop"
                                        placeholder="Válassz egyet!"
                                        :options="champaigns"
                                        @selectedItem="form.survey[0].answer = $event"
                                    />
                                </div>-->
                            </div>
                        </div>
                        <div class="form-inner-container form__inner form__inner--bottom" style="background-color:#FFF8BC;border-radius: 0 0px 30px 30px;">
                            <div class="col-12">
                                <CheckBox
                                    class="mt-4"
                                    name="rules"
                                    v-model="form.rules"
                                    :checked="form.rules"
                                    rules="required|acceptConditions"                                  
                                >
                                    <p 
                                        class="mb-0 text-black"
                                    >
                                        A <span>&nbsp;</span>
                                        <a class="text-red text-underline fw-700" :href="$store.state.file_url" target="_blank">Játékszabályzatot</a> és az <a class="text-red text-underline fw-700" :href="$store.state.privacy_url" target="_blank">Adatkezelési tájékoztatót</a>
                                        <span>&nbsp;</span>
                                        <span>megismertem, az abban foglaltakat elfogadom.</span>
                                        <span class="text-red fw-bold">*</span>
                                    </p>
                                </CheckBox>
                            </div>

                            <!-- SZEMÉLYES ADATOK -->
                            <div class="col-12">
                                <CheckBox
                                    class="mt-2"
                                    name="consent"
                                    v-model="form.consent"
                                    :checked="form.consent"
                                    rules="required|acceptConditions"   
                                >
                                    <p class="mb-0 text-black"> 
                                        Hozzájárulok, hogy az itt megadott személyes adataimat az Intersnack Magyarország Kft. a nyereményjáték lebonyolításával kapcsolatos célokból kezelje.
                                        <span class="text-red fw-bold">*</span>    
                                    </p>
                                </CheckBox>
                            </div>                        

                            <!-- SUBMIT --> 
                            
                            <div class="col-12 d-flex justify-content-center mt-md-5 mt-0">
                                <input 
                                    class="btn btn-primary btn-submit" 
                                    type="submit" 
                                    value="Regisztráció"
                                    @click="checkErrors()"
                                >
                            </div>
                        </div>
                    </form>
                </ValidationObserver> <!-- ITT VÉGZŐDIK A FORM -->
            </div>              
            <div class="row" v-else>
                <div class="col-12 col-md-8 offset-md-2 ">
                    <!-- <h2 class="font-header fw-bold text-white text-center mb-4 pb-2 pb-md-5 mb-sm-2 mt-0 text-uppercase">Regisztráció</h2>
                    <p class="text-white text-center mb-8">Személyes adataid úgy add meg, hogy azokat személyi igazolványoddal és lakcímkártyáddal tudd igazolni, amennyiben nyerteseink között tudhatunk majd. Az adatok megadása, így a játékban való részvétel önkéntes.</p> -->
                    <!-- Before status -->
                    <div v-if="$store.state.promoStatus == 'before'" class="py-5 text-center form-inner-container form-inner-container-shadow form__inner ">
                        <img src="@/assets/imgs/ic-soon.svg" class="img-fluid mb-3" />
                        <div class="h5 mb-5 fw-700 text-primary text-uppercase fs-24" >Köszönjük látogatását!</div>
                        <div class="text-black fs-16">Az oldalon már megtalálja a játékról szóló információkat.
Megkérjük, hogy látogasson vissza a promóció kezdetekor, <strong style="white-space:nowrap">2024. 08. 01. 9:00</strong> órától és játsszon velünk!</div>
                        <div class="h5 text-primary fw-700 mt-4 fs-22">Várjuk vissza!</div>
                    </div>
                    <!-- after status -->
                    <div v-if="$store.state.promoStatus == 'after'" class="py-5 text-center form-inner-container form-inner-container-shadow form__inner">
                        <img src="@/assets/imgs/ic-sad.svg" class="img-fluid mb-4"/>
                        <div class="fw-700 fs-24 text-primary text-uppercase">A nyereményjáték lezárult.</div>
                        <p>Köszönjük az érdeklődést!</p>
                    </div>
                </div>
            </div>          
        </div>
        <!-- REGISTRATION RESULT MODAL -->
        <!-- <RegisterSuccessModal :email="sendedEmail" :isSuccessReg="regSuccess"/> -->
    </div>
    
</template>

<script>
/**
 * after the reg go to the reg result page
 */

import { ValidationObserver } from 'vee-validate'
import CustomInput from '@/components/base/CustomInput.vue'
//import DropDown from '@/components/base/DropDown.vue'
import CheckBox from '@/components/base/CheckBox.vue'
//import SelectDate from '@/components/base/SelectDate.vue'
// import RegisterSuccessModal from '@/components/RegisterSuccessModal.vue'
// import errorMessages from "@/setup/errorMsgs.json";
import {mask} from 'vue-the-mask'

export default {
    directives: {mask},
    components: {
        ValidationObserver,
        CustomInput,
      //  DropDown,
        CheckBox,
       // SelectDate,
        // RegisterSuccessModal
    },
    data () {
        return {
            options: [],  
            champaigns:['Hungaria Extra Dry','Hungaria Rosé Extra Dry','Hungaria Irsai Olivér', 'Hungaria Grande Cuvée Brut','Hungaria Grande Cuvée Rouge','Hungaria Irsai Olivér Extra Dry'],         
            howOften:['Alkalmanként (félévente néhány alkalommal)', 'Csak a nyereményjátékban való részvétel kedvéért vásároltam','Rendszeresen (havonta)', 'Ritkán (évente egyszer, kétszer)' ],
            titles:['Nincs','Dr.', 'Prof.', 'Ifj.', 'Id.', 'Özv.'],
            years:['18-29', '30-39', '40-49', '50-59' ,'60+'],
            phoneShort: '',
            phoneRaw: '',
            form: {
                first_name: '',
                last_name: '',
                email: '',
                emailConf:'',
                phone:'',
                password: '',
                passconf: '',
              //  favourite_shop: '',
                rules: null,
              //  privacy: null,
                consent: null,
              //  marketing: 0,
              //  title: '',
              //  birth_date:'',
                survey:[{
                    question_number:1,
                    answer:null
                }]                                           
           },      
        }
    },
    computed:{
        shops(){
            return  this.$store.state.shops
        },
        formToSend()      {    
            // let _this = this        
            let data = this.form    

            return data
        }
    },
    methods: {
        changeMarketing(){
            if ( parseInt(this.form.marketing) === 0){
                this.form.birth_date = '',
                this.form.title = ''
            }
        },
        getItem(item){
            console.log(item)
            this.form.favorite_supermarket = item
        },
        getDate(date){
            console.log(date)
        },
        scrollToElement(element, diff){
            
            const offset = document.querySelector('.navbar').clientHeight + diff
            const bodyTop = document.body.getBoundingClientRect().top;
            const elementTop = element.getBoundingClientRect().top;
            const elementPos = elementTop - bodyTop;
            const offsetPos = elementPos - offset;

            window.scrollTo({
                top: offsetPos,
                behavior: 'smooth'
            });
        },
        onSubmit(){
            if (this.phoneShort){
                this.form.phone = '+36-' + this.phoneShort
            }

            var sendData = JSON.parse(JSON.stringify(this.formToSend));            

            this.post('register',sendData).then((res)=>{
                if (res.data.status == true){                    
                    //this.regSuccess = true                                  

                    //go to the reg 
                    this.$router.push({name: 'successReg', params: {result: 'success'}})
                    
                    //reset form
                    this.setFormDefaultState()       
                    this.setGtm('sikeres', 'sikeres_regisztracio')              
                }
                                
           }).catch((err)=>{                          
                //this.regSuccess = false   
                this.setGtm('sikertelen', 'sikertelen_regisztracio')                            
                this.goToError(err)                                                          
           })
      
        },
        analyzeErrors(err){
            return new Promise((resolve, reject) => {
                let errorsArray = Object.entries(err.response.data.error)
                for (const [errorName, errorValue] of errorsArray) {
                    // if (errorName == "email" && errorValue == "occupied" ){
                    //     this.$router.push({name: 'successReg', params: {result: 'not-success', details: 'occupied'}})
                    //     reject()
                    // } 
                    if (errorName == 'maximum_registration_same_ip' && errorValue == 'limit_reached'){
                        this.$router.push({name: 'successReg', params: {result: 'not-success', details: 'limit_reached'}})
                        reject()
                    } else{
                        resolve()
                    }
                }
            })
        },
        checkErrors(){
            let _this = this;
            setTimeout( function(){
                let firstError = document.querySelector('.register .input-error')
                if (firstError)                    
                    _this.scrollToElement(firstError, 80)
            }, 500)
        },
        goToError(err){            
            this.analyzeErrors(err).then(()=>{
                this.setErrorMsgs(err, 'observerReg').then(()=>{                
                    let firstError = document.querySelector('.register .input-error.active-error')
                    if (firstError)
                        this.scrollToElement(firstError, 80)       
                }).catch(()=>{
                    this.$router.push({name: 'successReg', params: {result: 'not-success'}})
                })   
            })
                                              
        },      
        setFormDefaultState(){
            this.form = {
                first_name: '',
                last_name: '',
                email: '',
                emailConf:'',
                phone:'',
                password: '',
                passconf: '',
              // favourite_shop: '',
                rules: null,
             //   privacy: null,
                consent: null,
             //   marketing: 0,
             //   title: '',
             //   birth_date:'',
                survey:{
                    question_number:1,
                    answer:null
                }
           }
           this.phoneShort = '';
           this.phoneRaw = '';
        },
        setGtm(result, eventName){
            
            this.GTtrackGA4({
                'event' : eventName,
                'category': 'regisztracio',
                'action': 'gomb_kattintas',                                
                'clicked_text':'Regisztráció',
                'button': 'regisztracio',
                'label': result,
                'success': result
            })
        
        }
    },
    created(){
        
    },
    mounted(){
        
    }
}
</script>