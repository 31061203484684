<template>    
    <div class="hero__outer">
        <div class="hero hero__inner" id="hero">        
            <div class="hero-container">
                <!-- CONTENT -->
                <div class="container">
                    <div class="row">
                        <div class="col-12 position-relative px-0">
                            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-indicators" v-if="$store.state.extra_status">
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div>
                                <div class="carousel-inner">
                                    <div class="carousel-item pb-5 active">
                                        <div class="row py-lg-5 position-relative">

                                            <div class="col-12 position-relative">
                                                <img src="@/assets/imgs/hero-full.png" class="img-fluid d-none d-lg-block" alt="pombar">
                                                <h1 class="text-white d-block d-lg-none px-5 mb-3 teaser-title" style="padding-top: 80px;">Ajándékot nyerhet</h1>
                                                <p class="fs-14 text-uppercase text-secondary d-block d-lg-none px-5">Vásároljon bármilyen POM-BÄR terméket, töltse fel vásárlásának adatait és megnyerheti a heti 1 db 200.000 Ft értékű Hunguest Hotels Ajándékkártya, a napi 30 db plüss POM-BÄR Maci vagy a napi 5 db POM-BÄR piknik szett egyikét!</p>
                                                <img src="@/assets/imgs/nyerjen.png" class="img-fluid d-block d-lg-none my-5 py-5" alt="pombar">
                                                <img src="@/assets/imgs/red-circle.png" class="img-fluid d-sm-block d-none d-lg-none position-absolute" style="top:0px;z-index:-1;"  alt="pombar">
                                                <img src="@/assets/imgs/red-circle-mobile.png" class="img-fluid d-block d-sm-none position-absolute red-circle" style="z-index:-1;"  alt="pombar">
                                                <p class="fs-20 text-black fw-bold d-md-none text-center" style="margin-top:-20px;padding-bottom:10px">A promóció időtartama:<br/> 2024.08.01-09.30</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="carousel-item" v-if="$store.state.extra_status">
                                        <div class="row py-5 mt-5 position-relative">
                                            <div class="col-12 pt-5 d-flex justify-content-around align-items-center flex-column flex-lg-row">
                                                <div class="d-flex flex-column">
                                                    <h1 class="text-primary teaser-title-rotate">Nyerjen!</h1>
                                                    <p class="fs-20 text-black fw-bold d-none d-lg-block">A promóció időtartama:<br>2024.08.15-08.28</p>
                                                </div>
                                                <h3 class="text-black fw-bold py-3 py-lg-0 text-center text-lg-start">Extra nyeremény kizárólag <br>a SPAR-csoport üzleteiben</h3>
                                            </div>
                                            <div class="col-12 text-center mb-5">
                                            <img src="@/assets/imgs/spar-extra.png" class="d-none d-lg-block mx-auto" alt="pombar" style="max-width:600px;width:100%;">
                                            <img src="@/assets/imgs/spar-extra-mobile.png" alt="Pombar" class="mx-auto img-fluid d-block d-lg-none tesco-winnings" width="200"/>
                                            <p class="fs-20 text-black fw-bold d-block d-lg-none mt-3">A promóció időtartama:<br>2024.08.15-08.28</p>
                                             </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item" v-if="$store.state.extra_status">
                                        <div class="row py-5 mt-5 position-relative">
                                            <div class="col-12 pt-5 d-flex justify-content-around align-items-center flex-column flex-lg-row">
                                                <div class="d-flex flex-column">
                                                    <h1 class="text-primary teaser-title-rotate">Nyerjen!</h1>
                                                    <p class="fs-20 text-black fw-bold d-none d-lg-block">A promóció időtartama:<br>2024.08.15-08.28</p>
                                                </div>
                                                <h3 class="text-black fw-bold py-3 py-lg-0 text-center text-lg-start">Választható extra nyeremény<br>kizárólag a TESCO -ban</h3>
                                            </div>
                                            <div class="col-12 text-center py-5 mb-5">
                                            <img src="@/assets/imgs/tesco-winnings.png" alt="pombar" class="img-fluid d-none d-lg-block">
                                            <img src="@/assets/imgs/tesco-winnings-mobile.png" alt="Pombar" class="mx-auto img-fluid d-block d-lg-none tesco-winnings" />
                                            <p class="fs-20 text-black fw-bold d-block d-lg-none mt-3">A promóció időtartama:<br>2024.08.15-08.28</p>
                                             </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <img src="@/assets/imgs/pombar-man.png" alt="bear" class="img-fluid pombar-man"/>
                        </div>                 
                    </div>  
                </div> 
            </div>  
            <div class="hero-under">
                <div class="container">
                    <div class="row py-lg-5 pt-5">
                        <div class="col-12 col-lg-6 py-lg-5 position-relative">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/4aUWQRsCzfU?si=BWLtG19Rm69dYb45" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen style="z-index:2;position:relative;"></iframe>
                            <img src="@/assets/imgs/bear-1.png" alt="bear" class="img-fluid video-bear d-none d-lg-block"/>                            
                            <p class="text-black text-center mt-5"><i>A képek illusztrációk</i></p>
                        </div>
                        <div class="col-12 col-lg-6 py-lg-5">
                            <div class="mx-5 text-center text-md-start">
                                <img src="@/assets/imgs/Claim_verlauf.png" alt="bear" class="img-fluid mx-auto d-block mb-2 d-md-none"/>
                                <h3 class="text-primary fw-bold">Nyerhet!</h3>
                                <p class="text-black text-uppercase fw-bold fs-16">Nyerje meg a 200.000 Ft értékű Hunguest Hotels Ajándékkártya vagy a napi 5 db Pom-Bär piknik szett vagy a napi 30 db plüss Pom-Bär Maci egyikét!</p>
                                <div class="text-center text-dark mt-4">
                                    <button
                                        class="btn btn-primary mb-3 order-md-2 me-md-2 md-btn"
                                        v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                                        @click="setGtm('Játszom')" v-html="!$store.state.user ? 'Regisztráció' : 'Játszom'"
                                    >
                                    </button>
                                    <a class="btn btn-secondary me-0 ms-lg-2 order-md-1 mb-3 md-btn" :href="$store.state.file_url" target="_blank" @click="setGtm('Játékszabályzat')">
                                        Játékszabályzat
                                    </a>   
                                </div> 
                                
                            </div>
                            <img src="@/assets/imgs/summer.png" alt="bear" class="img-fluid d-block d-lg-none"/>
                        </div>     
                    </div>    
                </div>   
            </div>      
        </div>
    </div>            
</template>

<script>

//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    methods: {      
        setGtm(btnName, eventName){
            this.GTtrackGA4({
                'event' : eventName,
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'button': 'gomb_kattintas'
            })
        }
    }
}
</script>